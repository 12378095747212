import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BadRequestModel } from '../models/bad-request-model';
import { ConversationModel } from '../models/bot/conversation.model';
import { PagedResultModel } from '../models/paged-result-model';
import { ConversationServiceProxy } from '../proxies/conversation-service-proxy';
import { MessageService } from './message-service';

@Injectable()
export class ConversationService {
    private _messageService: MessageService;
    private _conversationServiceProxy: ConversationServiceProxy;

    constructor(
        conversationServiceProxy: ConversationServiceProxy,
        messageService: MessageService) {
        this._conversationServiceProxy = conversationServiceProxy;
        this._messageService = messageService;
    }

    public getConversations(): Observable<PagedResultModel<ConversationModel>> {
        return this._conversationServiceProxy.getConversations().pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    public getConversation(id: string): Observable<ConversationModel> {
        return this._conversationServiceProxy.getConversation(id).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    public updateConversations(conversations: ConversationModel[]): void {
        this._conversationServiceProxy.updateConversations(conversations).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }

    public syncConversations(): void {
        this._conversationServiceProxy.syncConversations().subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }

    public rollbackConversations(): void {
        this._conversationServiceProxy.rollbackConversations().subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }

    public export(type: string): Observable<any> {
        return this._conversationServiceProxy.export(type);
    }

    public import(file: File): Observable<any> {
        return this._conversationServiceProxy.import(file);
    }
}
