import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MessageService {
  private subject: Subject<any>;

  sendMessage(message: any) {
    if (!this.subject) {
      return;
    }

    this.subject.next(message);
  }

  clearMessages() {
    if (!this.subject) {
      return;
    }

    this.subject.next();
  }

  getMessage(): Observable<any> {
    this.subject = new Subject<any>();
    return this.subject.asObservable();
  }
}
