export enum HttpStatusCode {
    Ok = 200,

    Created = 201,

    badRequest = 400,

    unauthorized = 401,

    forbid = 403,
}
