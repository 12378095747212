import { Injectable } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { JobModel } from '@app/models/job-model';
import { JobScheduleModel } from '@app/models/job-schedule-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { JobScheduleServiceProxy } from '@app/proxies/job-schedule-service-proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MessageService } from './message-service';

@Injectable()
export class JobScheduleService {
  private jobScheduleProxyService: JobScheduleServiceProxy;
  private messageService: MessageService;

  constructor(
    jobScheduleProxyService: JobScheduleServiceProxy,
    messageService: MessageService
  ) {
    this.jobScheduleProxyService = jobScheduleProxyService;
    this.messageService = messageService;
  }

  getJobSchedules(page: number, size: number): Observable<PagedResultModel<JobScheduleModel>> {
    return this.jobScheduleProxyService.getJobSchedules({
      'pageIndex': page.toString(),
      'pageSize': size.toString()
    }).pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }

  getJobs(): Observable<PagedResultModel<JobModel>> {
    return this.jobScheduleProxyService.getJobs().pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }

  execute(id: string): void {
    this.jobScheduleProxyService.execute(id).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }

  addJobSchedule(jobSchedule: JobScheduleModel): void {
    this.jobScheduleProxyService.addJobSchedule(jobSchedule).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage({
        'message': <string>proxy.result,
        'code': proxy.statusCode
      });
    });
  }

  updateJobSchedule(id: string, jobSchedule: JobScheduleModel): void {
    this.jobScheduleProxyService.updateJobSchedule(id, jobSchedule).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage({
        'message': <string>proxy.result,
        'code': proxy.statusCode
      });
    });
  }

  deleteJobSchedule(id: string): void {
    this.jobScheduleProxyService.deleteJobSchedule(id).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }
}
