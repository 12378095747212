import { Component, Inject } from '@angular/core';
import { DeleteDialogDataModel } from './delete-dialog-data-model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  private dialogRef: MatDialogRef<DeleteDialogComponent>;
  public data: DeleteDialogDataModel<any>;

  constructor(
    dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DeleteDialogDataModel<any>) {
    this.dialogRef = dialogRef;
    this.data = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close(this.data.data);
  }
}
