import { Injectable } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { SettingModel } from '@app/models/setting-model';
import { SettingServiceProxy } from '@app/proxies/setting-service-proxy';
import { MessageService } from '@app/services/message-service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class SettingService {
  private settingServiceProxy: SettingServiceProxy;
  private messageService: MessageService;
  private settings: SettingModel[] = [];

  constructor(
    settingServiceProxy: SettingServiceProxy,
    messageService: MessageService) {
    this.settingServiceProxy = settingServiceProxy;
    this.messageService = messageService;
  }

  set(settings: SettingModel[]): void {
    this.settingServiceProxy.setSettings(settings).subscribe((proxy) => {
      this.settings = [];

      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }

  list(): Observable<SettingModel[]> {
    if (this.settings.length === 0) {
      return this.settingServiceProxy.getSettings({}).pipe(mergeMap((proxy) => {
        this.settings = proxy.result;

        return of(this.settings);
      }));
    }

    return of(this.settings);
  }

  clear(): void {
    this.settings = [];
  }
}
