import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { UnauthorizedConfig } from './unauthorized.config';

export const UNAUTHORIZED_CONFIG = new InjectionToken<UnauthorizedConfig>('UNAUTHORIZED_CONFIG');

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class UnauthorizedModule {
    static forRoot(config: UnauthorizedConfig): ModuleWithProviders<UnauthorizedModule> {
        return {
            ngModule: UnauthorizedModule,
            providers: [
                { provide: UNAUTHORIZED_CONFIG, useValue: config }
            ]
        }
    }
}
