import { Injectable } from '@angular/core';
import { ConversationLogModel } from '@app/models/conversation-log-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { ConversationLogServiceProxy } from '@app/proxies/conversation-log-service-proxy';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
//import { MsalService } from '@azure/msal-angular';
import { FieldFilterModel } from '@app/models/field-filter-model';

@Injectable()
export class ConversationLogService {
    //private authService: MsalService;
    private conversationLogServiceProxy: ConversationLogServiceProxy;

    constructor(
        //authService: MsalService,
        conversationLogServiceProxy: ConversationLogServiceProxy
    ) {
        //this.authService = authService;
        this.conversationLogServiceProxy = conversationLogServiceProxy;
    }

    getConversationLogs(page: number, size: number, filters?: FieldFilterModel[]): Observable<PagedResultModel<ConversationLogModel>> {
        return this.conversationLogServiceProxy.getConversationLogs({
            'pageIndex': page.toString(),
            'pageSize': size.toString(),
            'filters': filters ? JSON.stringify(filters) : ''
        }).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    exportConversationLogs(type: string, filters?: FieldFilterModel[]): Observable<any> {
        //let storage = this.authService.getCacheStorage();
        //let token = storage.getAllAccessTokens(this.authService.clientId, this.authService.getUser().userIdentifier)[0].value.idToken;
        //return this.conversationLogServiceProxy.exportConversationLogs(type, filters, token);
        return this.conversationLogServiceProxy.exportConversationLogs(type, filters);
    }
}
