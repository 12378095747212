import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivityLogModel } from '@app/models/activity-log-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class ActivityLogServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  public getActivityLogs(params?: {
    [param: string]: string | string[];
  }, headers?: {
    [name: string]: string | string[];
    }): Observable<ProxyStatus<PagedResultModel<ActivityLogModel>>> {
    let url = `${this.baseUrl}/api/activitylogs/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      params: params,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processPagedResult<ActivityLogModel>(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processPagedResult<ActivityLogModel>(response);
        } catch (e) {
          return <Observable<ProxyStatus<PagedResultModel<ActivityLogModel>>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<PagedResultModel<ActivityLogModel>>>><any>throwError(response);
      }
    }));
  }

  protected processPagedResult<T>(response: HttpResponseBase): Observable<ProxyStatus<PagedResultModel<T>>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    let pagedResult = new PagedResultModel<T>();
    if (status === HttpStatusCode.Ok) {
      pagedResult.total = <number>result.total;
      pagedResult.rows = <T[]>result.rows;
      return of(new ProxyStatus(status, pagedResult));
    }

    pagedResult.total = 0;
    pagedResult.rows = [];
    return of(new ProxyStatus(status, pagedResult));
  }
}
