import { Observable, throwError, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';
import { SettingModel } from '@app/models/setting-model';
import { API_BASE_URL } from './service-proxy';
import { ProxyStatus } from './proxy-status';
import { HttpStatusCode } from './http-status-code';
import { BadRequestModel } from '@app/models/bad-request-model';

@Injectable()
export class SettingServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  getSettings(params?: {
    [param: string]: string | string[];
  }, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<SettingModel[]>> {
    let url = this.baseUrl + "/api/settings";
    let combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };

    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      params: params,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processGetSettingsResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processGetSettingsResult(response);
        }
        catch (e) {
          return <Observable<ProxyStatus<SettingModel[]>>><any>throwError(e);
        }
      }
      else {
        return <Observable<ProxyStatus<SettingModel[]>>><any>throwError(response);
      }
    }));
  }

  protected processGetSettingsResult(response: HttpResponseBase): Observable<ProxyStatus<SettingModel[]>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    if (status === HttpStatusCode.Ok) {
      return of(new ProxyStatus<SettingModel[]>(status, result));
    }

    return of(new ProxyStatus<SettingModel[]>(status, []));
  }

  setSettings(settings: SettingModel[], headers?: { [name: string]: string | string[]; }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = this.baseUrl + "/api/settings";
    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };

    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: settings,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
      return this.processSetSettingsResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processSetSettingsResult(response);
        }
        catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  protected processSetSettingsResult(response: HttpResponseBase): Observable<ProxyStatus<string | BadRequestModel>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : '';
    const error = response instanceof HttpErrorResponse ? response.error : undefined;

    if (status === HttpStatusCode.Ok) {
      return of(new ProxyStatus(status, result));
    }

    if (status === HttpStatusCode.badRequest) {
      let badRequest = new BadRequestModel(error);
      return of(new ProxyStatus(status, badRequest));
    }

    if (error) {
      return of(new ProxyStatus(status, error));
    }

    return of(new ProxyStatus(status, ''));
  }
}
