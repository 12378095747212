import { Injectable } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { UserModel } from '@app/models/user-model';
import { UserServiceProxy } from '@app/proxies/user-service-proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PasswordGrantModel } from '../models/password-grant-model';
import { HttpStatusCode } from '../proxies/http-status-code';
import { MessageService } from './message-service';
import { Cookie } from '../../shared/cookie';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';

@Injectable()
export class UserService {
    private _messageService: MessageService;
    private _userServiceProxy: UserServiceProxy;
    private _cookie: Cookie;
    private _router: Router;

    constructor(
        messageService: MessageService,
        userServiceProxy: UserServiceProxy,
        cookie: Cookie,
        router: Router
    ) {
        this._messageService = messageService;
        this._userServiceProxy = userServiceProxy;
        this._cookie = cookie;
        this._router = router;
    }

    getUsers(page: number, size: number): Observable<PagedResultModel<UserModel>> {
        return this._userServiceProxy.getUsers({
            'pageIndex': page.toString(),
            'pageSize': size.toString()
        }).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    getUser(id: string): Observable<UserModel> {
        return this._userServiceProxy.getUser(id).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    addUser(user: UserModel): void {
        this._userServiceProxy.addUser(user).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    updateUser(id: string, user: UserModel): void {
        this._userServiceProxy.updateUser(id, user).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    deleteUser(id: string): void {
        this._userServiceProxy.deleteUser(id).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }

    login(loginModel: PasswordGrantModel): void {
        this._userServiceProxy.login(loginModel).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            if (proxy.statusCode === HttpStatusCode.Ok) {
                this._messageService.sendMessage(true);
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }

    logout(): void {
        this._userServiceProxy.logout().subscribe((proxy) => {
            if (proxy.statusCode === HttpStatusCode.Ok) {
                this._router.navigate([AppConfig.loginUrl]);
                return;
            }
        });
    }
}
