import { Injectable } from '@angular/core';
import { ActionComponentModel } from '@app/models/bot/action-component.model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { ActionComponentServiceProxy } from '@app/proxies/action-component.service.proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ActionComponentService {
    private static components: ActionComponentModel[] = [];
    private actionComponentServiceProxy: ActionComponentServiceProxy;

    constructor(
        actionComponentServiceProxy: ActionComponentServiceProxy
    ) {
        this.actionComponentServiceProxy = actionComponentServiceProxy;
    }

    getActionComponents(): Observable<PagedResultModel<ActionComponentModel>> {
        if (ActionComponentService.components && ActionComponentService.components.length > 0) {
            let newPagedResult = new PagedResultModel<ActionComponentModel>();
            newPagedResult.total = ActionComponentService.components.length;
            newPagedResult.rows = ActionComponentService.components;
            return of(newPagedResult);
        }

        return this.actionComponentServiceProxy.getActionComponents().pipe(mergeMap((proxy) => {
            ActionComponentService.components = proxy.result.rows;
            return of(proxy.result);
        }));
    }
}
