import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { JobScheduleModel } from '@app/models/job-schedule-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';
import { BadRequestModel } from '@app/models/bad-request-model';
import { JobModel } from '@app/models/job-model';

@Injectable()
export class JobScheduleServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  public getJobSchedules(params?: {
    [param: string]: string | string[];
  }, headers?: {
      [name: string]: string | string[];
    }): Observable<ProxyStatus<PagedResultModel<JobScheduleModel>>> {
    let url = `${this.baseUrl}/api/jobschedules/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      params: params,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processPagedResult<JobScheduleModel>(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processPagedResult<JobScheduleModel>(response);
        } catch (e) {
          return <Observable<ProxyStatus<PagedResultModel<JobScheduleModel>>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<PagedResultModel<JobScheduleModel>>>><any>throwError(response);
      }
    }));
  }

  public getJobs(params?: {
    [param: string]: string | string[];
  }, headers?: {
    [name: string]: string | string[];
    }): Observable<ProxyStatus<PagedResultModel<JobModel>>> {
    let url = `${this.baseUrl}/api/jobschedules/jobs`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      params: params,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processPagedResult<JobModel>(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processPagedResult<JobModel>(response);
        } catch (e) {
          return <Observable<ProxyStatus<PagedResultModel<JobModel>>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<PagedResultModel<JobModel>>>><any>throwError(response);
      }
    }));
  }

  protected processPagedResult<T>(response: HttpResponseBase): Observable<ProxyStatus<PagedResultModel<T>>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    let pagedResult = new PagedResultModel<T>();
    if (status === HttpStatusCode.Ok) {
      pagedResult.total = <number>result.total;
      pagedResult.rows = <T[]>result.rows;
      return of(new ProxyStatus(status, pagedResult));
    }

    pagedResult.total = 0;
    pagedResult.rows = [];
    return of(new ProxyStatus(status, pagedResult));
  }

  public execute(id: string, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/jobschedules/execute/${id}`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  public addJobSchedule(jobSchedule: JobScheduleModel, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/jobschedules/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: jobSchedule,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  public updateJobSchedule(id: string, jobSchedule: JobScheduleModel, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/jobschedules/${id}`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: jobSchedule,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("put", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  public deleteJobSchedule(id: string, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/jobschedules/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: [id],
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("delete", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  protected processOperationResult(response: HttpResponseBase): Observable<ProxyStatus<string | BadRequestModel>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : '';
    const error = response instanceof HttpErrorResponse ? response.error : undefined;

    if (status === HttpStatusCode.Ok || status === HttpStatusCode.Created) {
      return of(new ProxyStatus(status, result));
    }

    if (status === HttpStatusCode.badRequest) {
      let badRequest = new BadRequestModel(error);
      return of(new ProxyStatus(status, badRequest));
    }

    if (error) {
      return of(new ProxyStatus(status, error));
    }

    return of(new ProxyStatus(status, ''));
  }
}
