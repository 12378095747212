import { Injectable } from '@angular/core';
import { MenuModel } from '@app/models/menu-model';
import { MenuServiceProxy } from '@app/proxies/menu-service-proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MenuService {
  private menuServiceProxy: MenuServiceProxy;

  constructor(
    menuServiceProxy: MenuServiceProxy
  ) {
    this.menuServiceProxy = menuServiceProxy;
  }

  getMenus(): Observable<MenuModel[]> {
    return this.menuServiceProxy.getMenus().pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  } 
}
