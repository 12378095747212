import { NgModule } from '@angular/core';
import { ActionComponentService } from './action-component.service';
import { ActivityLogService } from './activity-log-service';
import { ChannelService } from './channel-service';
import { ConversationLogService } from './conversation-log-service';
import { ConversationService } from './conversation-service';
import { EmoticonService } from './emoticon.service';
import { FeedbackLogService } from './feedback-log-service';
import { FeedbackService } from './feedback-service';
import { FileService } from './file-service';
import { JobScheduleService } from './job-schedule-service';
import { LuisService } from './luis-service';
import { MenuService } from './menu-service';
import { MessageService } from './message-service';
import { PermissionService } from './permission-service';
import { SettingService } from './setting-service';
import { UserGroupService } from './user-group-service';
import { UserService } from './user-service';

@NgModule({
    providers: [
        MenuService,
        MessageService,
        SettingService,
        LuisService,
        UserService,
        ChannelService,
        ConversationLogService,
        ActionComponentService,
        FileService,
        FeedbackService,
        JobScheduleService,
        ActivityLogService,
        FeedbackLogService,
        UserGroupService,
        PermissionService,
        ConversationService,
        EmoticonService
    ]
})
export class ServiceModule { }
