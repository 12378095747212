import { Injectable } from '@angular/core';
import { FeedbackModel } from '@app/models/feedback-model';
import { FeedbackServiceProxy } from '@app/proxies/feedback-service-proxy';
import { HttpStatusCode } from '@app/proxies/http-status-code';
import { MessageService } from './message-service';
import { ProxyStatus } from '@app/proxies/proxy-status';
import { BadRequestModel } from '@app/models/bad-request-model';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {
  private messageService: MessageService;
  private feedbackServiceProxy: FeedbackServiceProxy;

  constructor(
    feedbackServiceProxy: FeedbackServiceProxy,
    messageService: MessageService
  ) {
    this.feedbackServiceProxy = feedbackServiceProxy;
    this.messageService = messageService;
  }

  public verify(id: string, signature: string, timestamp: string, nonce: string): void {
    this.feedbackServiceProxy.verify({
      'id': id,
      'signature': signature,
      'timestamp': timestamp,
      'nonce': nonce
    }).subscribe((proxy: HttpStatusCode) => {
      if (proxy === HttpStatusCode.Ok) {
        this.messageService.sendMessage('');
        return;
      }

      this.messageService.sendMessage(proxy);
    });
  }

  public updateFeedback(id: string, signature: string, timestamp: string, nonce: string, feedback: FeedbackModel): void {
    this.feedbackServiceProxy.updateFeedback(id, feedback, {
      'signature': signature,
      'timestamp': timestamp,
      'nonce': nonce
    }).subscribe((proxy: ProxyStatus<string | BadRequestModel>) => {
      if (proxy.statusCode === HttpStatusCode.Ok) {
        this.messageService.sendMessage(proxy.result);
        return;
      }

      this.messageService.sendMessage(proxy.statusCode);
    });
  }

  public addFile(id: string, signature: string, timestamp: string, nonce: string, file: File): Observable<any> {
    return this.feedbackServiceProxy.addFile(id, file, {
      'signature': signature,
      'timestamp': timestamp,
      'nonce': nonce
    });
  }
}
