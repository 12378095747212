import { Injectable } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { FieldFilterModel } from '@app/models/field-filter-model';
import { LuisApplicationModel } from '@app/models/luis-application-model';
import { NewLuisApplicationModel } from '@app/models/new-luis-application-model';
import { UpdateLuisApplicationModel } from '@app/models/update-luis-application-model';
import { LuisServiceProxy } from '@app/proxies/luis-service-proxy';
//import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MessageService } from './message-service';

@Injectable()
export class LuisService {
  private luisServiceProxy: LuisServiceProxy;
  private messageService: MessageService;
  //private authService: MsalService;

  constructor(
    //authService: MsalService,
    luisServiceProxy: LuisServiceProxy,
    messageService: MessageService) {
    this.luisServiceProxy = luisServiceProxy;
    this.messageService = messageService;
    //this.authService = authService;
  }

  getApplications(filters?: FieldFilterModel[]): Observable<LuisApplicationModel[]> {
    return this.luisServiceProxy.getApplications({
      'filters': filters ? JSON.stringify(filters) : ''
    }).pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }

  getApplication(id: string): Observable<LuisApplicationModel> {
    return this.luisServiceProxy.getApplication(id).pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }

  //exportApplication(id: string, type: string): Observable<any> {
  //  let storage = this.authService.getCacheStorage();
  //  let token = storage.getAllAccessTokens(this.authService.clientId, this.authService.getUser().userIdentifier)[0].value.idToken;
  //  return this.luisServiceProxy.exportApplication(id, type, token);
  //}

  importApplication(id: string, file: File): Observable<any> {
    return this.luisServiceProxy.importApplication(id, file);
  }

  addApplication(application: NewLuisApplicationModel): void {
    this.luisServiceProxy.addApplication(application).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }

  updateApplication(id: string, application: UpdateLuisApplicationModel): void {
    this.luisServiceProxy.updateApplication(id, application).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }

  deleteApplication(id: string): void {
    this.luisServiceProxy.deleteApplication(id).subscribe((proxy) => {
      if (proxy.result instanceof BadRequestModel) {
        let self = this;
        for (let error in proxy.result) {
          (<Array<string>>proxy.result[error]).forEach(function (value) {
            self.messageService.sendMessage(value);
          });
        }
        return;
      }

      this.messageService.sendMessage(<string>proxy.result);
    });
  }
}
