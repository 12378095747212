export class AuthenticatedResultModel {
  constructor(data?: any) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  accessToken: string | undefined;

  tokenType: string | undefined;

  expiresIn: number | undefined;

  refreshToken: string | undefined;
}
