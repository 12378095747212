import { Injectable, Inject, Optional } from '@angular/core';
import { API_BASE_URL } from './service-proxy';
import { Observable, of, throwError, Subject } from 'rxjs';
import { ProxyStatus } from './proxy-status';
import { PagedResultModel } from '@app/models/paged-result-model';
import { HttpResponse, HttpResponseBase, HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpStatusCode } from './http-status-code';
import { catchError, mergeMap } from 'rxjs/operators';
import { ConversationLogModel } from '@app/models/conversation-log-model';
import { FieldFilterModel } from '@app/models/field-filter-model';

@Injectable()
export class ConversationLogServiceProxy {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    public getConversationLogs(params?: {
        [param: string]: string | string[];
    }, headers?: {
        [name: string]: string | string[];
    }): Observable<ProxyStatus<PagedResultModel<ConversationLogModel>>> {
        let url = `${this.baseUrl}/api/conversationlogs/`;

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            params: params,
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return this.processPagedResult<ConversationLogModel>(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processPagedResult<ConversationLogModel>(response);
                } catch (e) {
                    return <Observable<ProxyStatus<PagedResultModel<ConversationLogModel>>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<PagedResultModel<ConversationLogModel>>>><any>throwError(response);
            }
        }));
    }

    protected processPagedResult<T>(response: HttpResponseBase): Observable<ProxyStatus<PagedResultModel<T>>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : undefined;

        let pagedResult = new PagedResultModel<T>();
        if (status === HttpStatusCode.Ok) {
            pagedResult.total = <number>result.total;
            pagedResult.rows = <T[]>result.rows;
            return of(new ProxyStatus(status, pagedResult));
        }

        pagedResult.total = 0;
        pagedResult.rows = [];
        return of(new ProxyStatus(status, pagedResult));
    }

    public exportConversationLogs(type: string, filters?: FieldFilterModel[], token?: string): Observable<any> {
        let url = `${this.baseUrl}/api/conversationlogs/export?type=${type}&filters=${filters ? JSON.stringify(filters) : ''}`;
        let subject = new Subject<any>();
        let xhr = new XMLHttpRequest();
        let date = new Date();
        let month = date.getMonth() + 1;
        let fileName = `${date.getFullYear()}${month < 10 ? '0' + month : month}${date.getDate()}.${type}`;

        xhr.open('GET', url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                    let data_url = URL.createObjectURL(xhr.response);

                    subject.next({
                        url: data_url,
                        fileName: fileName
                    });

                    window.URL.revokeObjectURL(url);
                } else {
                    subject.next(xhr.statusText);
                }
            }
        };
        xhr.responseType = 'blob';
        if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }
        xhr.send();

        return subject.asObservable();
    }
}
