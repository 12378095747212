import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';

const routes: Routes = [
    { path: 'portal', loadChildren: () => import('./pages/shared/layout/layout.module').then(m => m.LayoutModule) },
    { path: 'feedback', loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackModule) },
    { path: 'termsofuse', component: TermsOfUseComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: '', redirectTo: '/portal', pathMatch: 'full' },
    { path: 'portal/login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
