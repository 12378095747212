import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { MsalInterceptor, MsalModule } from '@azure/msal-angular';  //"@azure/msal-angular": "^0.1.4-beta.1",
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { UnauthorizedInterceptor } from './infrastructure/unauthorized.interceptor';
import { UnauthorizedModule } from './infrastructure/unauthorized.module';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { DeleteDialogComponent } from './pages/shared/components/delete-dialog/delete-dialog.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { API_BASE_URL } from './proxies/service-proxy';
import { ServiceProxyModule } from './proxies/service-proxy.module';
import { ServiceModule } from './services/service.module';

export function loggerCallback(logLevel, message, piiEnabled) {
    console.log(message);
}

@NgModule({
    declarations: [
        AppComponent,
        DeleteDialogComponent,
        HomeComponent,
        PrivacyComponent,
        TermsOfUseComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDialogModule,
        ServiceModule,
        ServiceProxyModule,
        HttpClientModule,
        UnauthorizedModule.forRoot({
            loginUrl: AppConfig.loginUrl,
            excludedResources: [`${AppConfig.baseUrl()}/api/feedbacks/`]
        })
        //MsalModule.forRoot({
        //    clientID: "fd8f1391-0f88-413a-901e-5b370fdac736",
        //    authority: "https://login.chinacloudapi.cn/8869cca1-07fb-41f8-9138-614f18caca7e/",
        //    popUp: false,
        //    consentScopes: ["user.read", "api://fd8f1391-0f88-413a-901e-5b370fdac736/access_as_user"],
        //    validateAuthority: true,
        //    logger: loggerCallback,
        //    unprotectedResources: [`${getRemoteServiceBaseUrl()}/api/feedbacks/`, 'assets'],
        //    redirectUri: `${getRemoteServiceBaseUrl()}/portal`,
        //    navigateToLoginRequestUrl: true
        //})
    ],
    providers: [
        {
            provide: API_BASE_URL,
            useFactory: AppConfig.baseUrl
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true
        },
        //{
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: MsalInterceptor,
        //    multi: true
        //}
    ],
    bootstrap: [AppComponent],
    entryComponents: [
      DeleteDialogComponent
  ]
})
export class AppModule { }
