import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { FileType } from '@app/models/file-type.enum';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { BadRequestModel } from '../models/bad-request-model';
import { HttpStatusCode } from './http-status-code';
import { API_BASE_URL } from './service-proxy';
import { ProxyStatus } from './proxy-status';

@Injectable()
export class FileServiceProxy {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    public addFile(file: File, type: FileType, headers?: {
        [name: string]: string | string[];
    }): Observable<any> {
        let url = `${this.baseUrl}/api/files/`;

        var combinedHeaders = {
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        const formData = new FormData();
        formData.append('files', file, file.name);
        formData.append('type', `${type}`);

        let options: any = {
            body: formData,
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("post", url, options);
    }

    public uploadFile(file: File, type: FileType, headers?: {
        [name: string]: string | string[];
    }): Observable<any> {
        let url = `${this.baseUrl}/api/files/`;
        let subject = new Subject<any>();

        var combinedHeaders = {
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        const formData = new FormData();
        formData.append('files', file, file.name);
        formData.append('type', `${type}`);

        let options: any = {
            body: formData,
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
            return this.processOperationResult(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processOperationResult(response);
                } catch (e) {
                    return <Observable<any>><any>throwError(e);
                }
            } else {
                return <Observable<any>><any>throwError(response);
            }
        }));
    }

    protected processOperationResult(response: any): Observable<any> {
        const type = response.type;
        if (type === HttpEventType.UploadProgress) {
            let rate = Math.round(response.loaded / response.total * 100);
            return of({ type, rate });
        }

        if (type !== HttpEventType.Response) {
            return of({ type });
        }

        const status = response.status;
        const body = response instanceof HttpResponse ? response.body : '';
        const error = response instanceof HttpErrorResponse ? response.error : undefined;

        if (status === HttpStatusCode.Ok || status === HttpStatusCode.Created) {
            return of({ type, status, body });
        }

        if (status === HttpStatusCode.badRequest) {
            let bad = new BadRequestModel(error);
            return of({ type, status, bad });
        }

        return of({ type, status, error });
    }

    public getFileUrl(params?: {
        [param: string]: string | string[];
    }, headers?: {
        [name: string]: string | string[];
    }): Observable<ProxyStatus<string>> {
        let url = `${this.baseUrl}/api/files/geturl`;

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            params: params,
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return this.processGetModelResult<string>(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processGetModelResult<string>(response);
                } catch (e) {
                    return <Observable<ProxyStatus<string>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<string>>><any>throwError(response);
            }
        }));
    }

    protected processGetModelResult<T>(response: HttpResponseBase): Observable<ProxyStatus<T>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : undefined;

        if (status === HttpStatusCode.Ok) {
            return of(new ProxyStatus(status, result));
        }

        return of(new ProxyStatus(status, undefined));
    }
}
