import { NgModule } from '@angular/core';
import { ActionComponentServiceProxy } from './action-component.service.proxy';
import { ActivityLogServiceProxy } from './activity-log-service-proxy';
import { ChannelServiceProxy } from './channel-service-proxy';
import { ConversationLogServiceProxy } from './conversation-log-service-proxy';
import { ConversationServiceProxy } from './conversation-service-proxy';
import { EmoticonServiceProxy } from './emoticon.service.proxy';
import { FeedbackLogServiceProxy } from './feedback-log-service-proxy';
import { FeedbackServiceProxy } from './feedback-service-proxy';
import { FileServiceProxy } from './file-service-proxy';
import { JobScheduleServiceProxy } from './job-schedule-service-proxy';
import { LuisServiceProxy } from './luis-service-proxy';
import { MenuServiceProxy } from './menu-service-proxy';
import { PermissionServiceProxy } from './permission-service-proxy';
import { SettingServiceProxy } from './setting-service-proxy';
import { TokenServiceProxy } from './token-service-proxy';
import { UserGroupServiceProxy } from './user-group-service-proxy';
import { UserServiceProxy } from './user-service-proxy';

@NgModule({
    providers: [
        TokenServiceProxy,
        MenuServiceProxy,
        SettingServiceProxy,
        LuisServiceProxy,
        UserServiceProxy,
        ChannelServiceProxy,
        ConversationLogServiceProxy,
        ActionComponentServiceProxy,
        FileServiceProxy,
        FeedbackServiceProxy,
        JobScheduleServiceProxy,
        ActivityLogServiceProxy,
        FeedbackLogServiceProxy,
        UserGroupServiceProxy,
        PermissionServiceProxy,
        ConversationServiceProxy,
        EmoticonServiceProxy
    ]
})
export class ServiceProxyModule { }
