import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UnauthorizedConfig } from './unauthorized.config';
import { UNAUTHORIZED_CONFIG } from './unauthorized.module';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private _config: UnauthorizedConfig;
    private _router: Router;

    constructor(@Inject(UNAUTHORIZED_CONFIG) config: UnauthorizedConfig, router: Router) {
        this._config = config;
        this._router = router;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(null,
            (e: any) => {
                if (e instanceof HttpErrorResponse) {
                    if (e.status === 401) {
                        if (this.isExcluded(e.url)) {
                            return;
                        }

                        this._router.navigate([this._config.loginUrl]);
                    }
                }
            }
        ));
    }

    private isExcluded(url: string) {
        url = url.toLocaleLowerCase();

        if (this._config.excludedResources && this._config.excludedResources.length > 0) {
            if (this._config.excludedResources.findIndex((r: string) => {
                return url.indexOf(r.toLocaleLowerCase()) !== -1;
            }) !== -1) {
                return true;
            }
        }

        return false;
    }
}
