export abstract class AppConfig {
  //private static _azureAD: undefined | AzureAD;

    public static baseUrl(): string {
        if (!document.location.origin) {
            const port = document.location.port ? ':' + document.location.port : '';
            return document.location.protocol + '//' + document.location.hostname + port;
        }

        return document.location.origin;
    }

    public static get loginUrl(): string {
        return '/portal/login';
    }

    public static get authenticationCookieName(): string {
        return 'bot.auth';
    }

  public static get AzureAD(): AzureAD {
    let azureAD = new AzureAD();
    azureAD.clientId = '78b1d414-8f36-42a2-acaf-5b0eaaa6494d';
    azureAD.tenantId = 'eef727ff-82de-4c60-902b-830774836bfe';
    return azureAD;
        //if (AppConfig._azureAD) {
        //    return AppConfig._azureAD;
        //}

        //AppConfig._azureAD = new AzureAD();
        //AppConfig._azureAD.clientId = '78b1d414-8f36-42a2-acaf-5b0eaaa6494d';
        //AppConfig._azureAD.tenantId = 'eef727ff-82de-4c60-902b-830774836bfe';
        //return AppConfig._azureAD;
        
    }
}

export class AzureAD {
    clientId: undefined | string;

    tenantId: undefined | string;
}
