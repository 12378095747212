import { Observable, throwError, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';
import { MenuModel } from '@app/models/menu-model';
import { API_BASE_URL } from './service-proxy';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';

@Injectable()
export class MenuServiceProxy {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    getMenus(headers?: {
        [name: string]: string | string[];
    }): Observable<ProxyStatus<MenuModel[]>> {
        let url = this.baseUrl + "/api/menus";

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return this.processGetMenusResult(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processGetMenusResult(response);
                } catch (e) {
                    return <Observable<ProxyStatus<MenuModel[]>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<MenuModel[]>>><any>throwError(response);
            }
        }));
    }

    protected processGetMenusResult(response: HttpResponseBase): Observable<ProxyStatus<MenuModel[]>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : undefined;

        if (status === HttpStatusCode.Ok) {
            return of(new ProxyStatus(status, result));
        }

        return of(new ProxyStatus(status, []));
    }
}
