import { Injectable } from '@angular/core';
import { FileType } from '@app/models/file-type.enum';
import { FileServiceProxy } from '@app/proxies/file-service-proxy';
import { Observable } from 'rxjs';
import { ProxyStatus } from '../proxies/proxy-status';

@Injectable()
export class FileService {
    private _fileServiceProxy: FileServiceProxy;

    constructor(
        fileServiceProxy: FileServiceProxy
    ) {
        this._fileServiceProxy = fileServiceProxy;
    }

    public addFile(file: File, type: FileType): Observable<any> {
        return this._fileServiceProxy.addFile(file, type);
    }

    public uploadFile(file: File, type: FileType): Observable<any> {
        return this._fileServiceProxy.uploadFile(file, type);
    }

    public getFileUrl(filename: string): Observable<ProxyStatus<string>> {
        return this._fileServiceProxy.getFileUrl({
            "filename": filename
        });
    }
}
