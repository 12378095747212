//import { MsalService } from '@azure/msal-angular';
import { PagedResultModel } from '@app/models/paged-result-model';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FeedbackLogServiceProxy } from '@app/proxies/feedback-log-service-proxy';
import { FeedbackLogModel } from '@app/models/feedback-log-model';

@Injectable()
export class FeedbackLogService {
    //private authService: MsalService;
    private feedbackLogServiceProxy: FeedbackLogServiceProxy;

    constructor(
        //authService: MsalService,
        feedbackLogServiceProxy: FeedbackLogServiceProxy
    ) {
        //this.authService = authService;
        this.feedbackLogServiceProxy = feedbackLogServiceProxy;
    }

    getFeedbackLogs(page: number, size: number): Observable<PagedResultModel<FeedbackLogModel>> {
        //let storage = this.authService.getCacheStorage();
        //let token = storage.getAllAccessTokens(this.authService.clientId, this.authService.getUser().userIdentifier)[0].value.idToken;

        //return this.feedbackLogServiceProxy.getFeedbackLogs({
        //    'pageIndex': page.toString(),
        //    'pageSize': size.toString()
        //}, {
        //    'Authorization': `Bearer ${token}`
        //}).pipe(mergeMap((proxy) => {
        //    return of(proxy.result);
        //}));

        return this.feedbackLogServiceProxy.getFeedbackLogs({
            'pageIndex': page.toString(),
            'pageSize': size.toString()
        }).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    getFeedbackLog(id: string): Observable<FeedbackLogModel> {
        //let storage = this.authService.getCacheStorage();
        //let token = storage.getAllAccessTokens(this.authService.clientId, this.authService.getUser().userIdentifier)[0].value.idToken;

        //return this.feedbackLogServiceProxy.getFeedbackLog(id, {
        //    'Authorization': `Bearer ${token}`
        //}).pipe(mergeMap((proxy) => {
        //    return of(proxy.result);
        //}));

        return this.feedbackLogServiceProxy.getFeedbackLog(id).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }
}
