import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ChannelModel } from '@app/models/channel-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class ChannelServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  public getChannels(headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<ChannelModel[]>> {
    let url = `${this.baseUrl}/api/channels/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processGetModelsResult<ChannelModel[]>(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processGetModelsResult<ChannelModel[]>(response);
        } catch (e) {
          return <Observable<ProxyStatus<ChannelModel[]>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<ChannelModel[]>>><any>throwError(response);
      }
    }));
  }

  protected processGetModelsResult<T>(response: HttpResponseBase): Observable<ProxyStatus<T>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    if (status === HttpStatusCode.Ok) {
      return of(new ProxyStatus(status, result));
    }

    return of(new ProxyStatus(status, <any>[]));
  }
}
