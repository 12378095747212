import { Injectable } from '@angular/core';
import { AccessAbility } from '@app/models/access-ability.enum';
import { PermissionModel } from '@app/models/permission-model';
import { HttpStatusCode } from '@app/proxies/http-status-code';
import { PermissionServiceProxy } from '@app/proxies/permission-service-proxy';
//import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserService } from './user-service';

@Injectable()
export class PermissionService {
    private _permissionServiceProxy: PermissionServiceProxy;
    private _userService: UserService;
    //private authService: MsalService;
    private static _permissions: boolean | PermissionModel[];

    constructor(
        permissionServiceProxy: PermissionServiceProxy,
        userService: UserService,
        //authService: MsalService
    ) {
        this._permissionServiceProxy = permissionServiceProxy;
        this._userService = userService;
        //this.authService = authService;
    }

    public getPermissions(): Observable<PermissionModel[]> {
        return this._permissionServiceProxy.getPermissions().pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    public hasPermission(code: string, accessAbility: AccessAbility): Observable<boolean> {
        if (!PermissionService._permissions) {
            return this._permissionServiceProxy.getPermissionsByLoggedInUser().pipe(mergeMap((p) => {
                if (p.statusCode === HttpStatusCode.unauthorized) {
                    //this.authService.logout();
                    this._userService.logout();
                    return of(false);
                }

                if (p.statusCode === HttpStatusCode.forbid) {
                    return of(false);
                }

                if (p.result.isSuper) {
                    PermissionService._permissions = true;
                    return of(true);
                }

                PermissionService._permissions = p.result.permissions;
                return this.validatePermission(code, accessAbility);
            }));
        }
        
        return this.validatePermission(code, accessAbility);
    }

    private validatePermission(code: string, accessAbility: AccessAbility): Observable<boolean> {
        if (typeof (PermissionService._permissions) == "boolean" && PermissionService._permissions) {
            return of(true);
        }

        let permission = (<PermissionModel[]>PermissionService._permissions).find(p => p.code === code);
        if (!permission) {
            return of(false);
        }

        let foundIndex = permission.abilities.findIndex(a => a.value as AccessAbility === accessAbility);
        return of(foundIndex > -1);
    }
}
