import { Injectable } from '@angular/core';
import { ChannelModel } from '@app/models/channel-model';
import { ChannelServiceProxy } from '@app/proxies/channel-service-proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ChannelService {
  private _channelServiceProxy: ChannelServiceProxy;

  constructor(
    channelServiceProxy: ChannelServiceProxy
  ) {
    this._channelServiceProxy = channelServiceProxy;
  }

  getChannels(): Observable<ChannelModel[]> {
    return this._channelServiceProxy.getChannels().pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }
}
