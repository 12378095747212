import { Injectable } from '@angular/core';
import { ActivityLogModel } from '@app/models/activity-log-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { ActivityLogServiceProxy } from '@app/proxies/activity-log-service-proxy';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ActivityLogService {
  private _activityLogServiceProxy: ActivityLogServiceProxy;

  constructor(
    activityLogServiceProxy: ActivityLogServiceProxy
  ) {
    this._activityLogServiceProxy = activityLogServiceProxy;
  }

  getActivityLogs(page: number, size: number): Observable<PagedResultModel<ActivityLogModel>> {
    return this._activityLogServiceProxy.getActivityLogs({
      'pageIndex': page.toString(),
      'pageSize': size.toString()
    }).pipe(mergeMap((proxy) => {
      return of(proxy.result);
    }));
  }
}
