import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { FeedbackModel } from '@app/models/feedback-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class FeedbackServiceProxy {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    public verify(params: {
        [param: string]: string | any[];
    }, headers?: {
        [name: string]: string | string[];
    }): Observable<HttpStatusCode> {
        let url = `${this.baseUrl}/api/feedbacks/verify`;

        let combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            params: params,
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return of(response.status);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return of(response.status);
                } catch (e) {
                    return <Observable<HttpStatusCode>><any>throwError(e);
                }
            } else {
                return <Observable<HttpStatusCode>><any>throwError(response);
            }
        }));
    }

    protected processOperationResult(response: HttpResponseBase): Observable<ProxyStatus<string | BadRequestModel>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : '';
        const error = response instanceof HttpErrorResponse ? response.error : undefined;

        if (status === HttpStatusCode.Ok || status === HttpStatusCode.Created) {
            return of(new ProxyStatus(status, result));
        }

        if (status === HttpStatusCode.badRequest) {
            let badRequest = new BadRequestModel(error);
            return of(new ProxyStatus(status, badRequest));
        }

        if (error) {
            return of(new ProxyStatus(status, error));
        }

        return of(new ProxyStatus(status, ''));
    }

    public updateFeedback(id: string, feedback: FeedbackModel, params: {
        [param: string]: string | any[];
    }, headers?: {
        [name: string]: string | string[];
    }): Observable<ProxyStatus<string | BadRequestModel>> {
        let url = `${this.baseUrl}/api/feedbacks/${id}`;

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            params: params,
            body: feedback,
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("put", url, options).pipe(mergeMap((response: any) => {
            return this.processOperationResult(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processOperationResult(response);
                } catch (e) {
                    return <Observable<ProxyStatus<string>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<string>>><any>throwError(response);
            }
        }));
    }

    public addFile(id: string, file: File, params: {
        [param: string]: string | any[];
    }, headers?: {
        [name: string]: string | string[];
    }): Observable<any> {
        let url = `${this.baseUrl}/api/feedbacks/${id}/uploadfile`;

        var combinedHeaders = {
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        const formData = new FormData();
        formData.append('files', file, file.name);

        let options: any = {
            params: params,
            body: formData,
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("post", url, options);
    }
}
