import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Cookie {
  get(key: string): string {
    if (!key) {
      return null;
    }

    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  }

  set(key: string, value: string, expires?: number | string | Date | null | undefined, domain?: string, path?: string, secure?: boolean): void {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return;
    }

    var expiresOn = "";
    if (expires) {
      switch (expires.constructor) {
        case Number:
          expiresOn = expires === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + expires;
          /*
          Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
          version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
          the end parameter might not work as expected. A possible solution might be to convert the the
          relative time to an absolute time. For instance, replacing the previous line with:
          */
          /*
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
          */
          break;
        case String:
          expiresOn = "; expires=" + expires;
          break;
        case Date:
          expiresOn = "; expires=" + (<Date>expires).toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(key) + "=" + encodeURIComponent(value) + expiresOn + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : "") + (secure ? "; secure" : "");
  }

  remove(key: string, path?: string, domain?: string): boolean {
    if (!this.exists(key)) { return false; }
    document.cookie = encodeURIComponent(key) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : "");
    return true;
  }

  exists(key: string): boolean {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false;
    }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  }
}
