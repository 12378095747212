import { HttpStatusCode } from './http-status-code';

export class ProxyStatus<T> {
  statusCode: HttpStatusCode | undefined;

  result: T | undefined;

  constructor(statusCode?: HttpStatusCode, result?: T) {
    this.statusCode = statusCode;
    this.result = result
  }
}
