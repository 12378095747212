import { Injectable } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EmoticonModel } from '../models/emoticon.model';
import { EmoticonServiceProxy } from '../proxies/emoticon.service.proxy';
import { MessageService } from './message-service';

@Injectable()
export class EmoticonService {
    private _messageService: MessageService;
    private _emoticonServiceProxy: EmoticonServiceProxy;

    constructor(
        messageService: MessageService,
        emoticonServiceProxy: EmoticonServiceProxy
    ) {
        this._messageService = messageService;
        this._emoticonServiceProxy = emoticonServiceProxy;
    }

    getEmoticons(page: number, size: number): Observable<PagedResultModel<EmoticonModel>> {
        return this._emoticonServiceProxy.getEmoticons({
            'pageIndex': page.toString(),
            'pageSize': size.toString()
        }).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    getEmoticon(id: string): Observable<EmoticonModel> {
        return this._emoticonServiceProxy.getEmoticon(id).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    addEmoticon(emoticon: EmoticonModel): void {
        this._emoticonServiceProxy.addEmoticon(emoticon).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    updateEmoticon(id: string, emoticon: EmoticonModel): void {
        this._emoticonServiceProxy.updateEmoticon(id, emoticon).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    deleteEmoticon(id: string): void {
        this._emoticonServiceProxy.deleteEmoticon(id).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self._messageService.sendMessage(value);
                    });
                }
                return;
            }

            this._messageService.sendMessage(<string>proxy.result);
        });
    }
}
