import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { PermissionModel } from '../models/permission-model';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class PermissionServiceProxy {
    private http: HttpClient;
    private baseUrl: string;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    getPermissions(headers?: {
        [name: string]: string | string[];
    }): Observable<ProxyStatus<PermissionModel[]>> {
        let url = this.baseUrl + "/api/permissions";

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
        if (headers) {
            combinedHeaders = { ...combinedHeaders, ...headers };
        }

        let options: any = {
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return this.processGetPermissionsResult(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processGetPermissionsResult(response);
                } catch (e) {
                    return <Observable<ProxyStatus<PermissionModel[]>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<PermissionModel[]>>><any>throwError(response);
            }
        }));
    }

    protected processGetPermissionsResult(response: HttpResponseBase): Observable<ProxyStatus<PermissionModel[]>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : undefined;

        if (status === HttpStatusCode.Ok) {
            return of(new ProxyStatus(status, result));
        }

        return of(new ProxyStatus(status, []));
    }

    getPermissionsByLoggedInUser(): Observable<ProxyStatus<any>> {
        let url = this.baseUrl + "/api/permissions/loggedinuser";

        var combinedHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };

        let options: any = {
            observe: "response",
            headers: new HttpHeaders(combinedHeaders)
        };

        return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
            return this.processGetPermissionsByLoggedInUserResult(response);
        })).pipe(catchError((response: any) => {
            if (response instanceof HttpResponseBase) {
                try {
                    return this.processGetPermissionsByLoggedInUserResult(response);
                } catch (e) {
                    return <Observable<ProxyStatus<any>>><any>throwError(e);
                }
            } else {
                return <Observable<ProxyStatus<any>>><any>throwError(response);
            }
        }));
    }

    protected processGetPermissionsByLoggedInUserResult(response: HttpResponseBase): Observable<ProxyStatus<any>> {
        const status = response.status;
        const result = response instanceof HttpResponse ? response.body : undefined;

        if (status === HttpStatusCode.Ok) {
            return of(new ProxyStatus(status, result));
        }

        return of(new ProxyStatus(status, result));
    }
}
