import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpResponseBase, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BadRequestModel } from '@app/models/bad-request-model';
import { EditLuisPatternModel } from '@app/models/edit-luis-pattern-model';
import { LuisApplicationModel } from '@app/models/luis-application-model';
import { LuisEntityModel } from '@app/models/luis-entity-model';
import { LuisIntentModel } from '@app/models/luis-intent-model';
import { LuisPatternModel } from '@app/models/luis-pattern-model';
import { LuisPhraseModel } from '@app/models/luis-phrase-model';
import { LuisPrebuiltEntityModel } from '@app/models/luis-prebuilt-entity-model';
import { LuisUtteranceModel } from '@app/models/luis-utterance-model';
import { NewLuisApplicationModel } from '@app/models/new-luis-application-model';
import { NewLuisEntityModel } from '@app/models/new-luis-entity-model';
import { NewLuisPhraseModel } from '@app/models/new-luis-phrase-model';
import { PagedResultModel } from '@app/models/paged-result-model';
import { UpdateLuisApplicationModel } from '@app/models/update-luis-application-model';
import { UpdateLuisCompositeEntityModel } from '@app/models/update-luis-composite-entity-model';
import { UpdateLuisListEntityModel } from '@app/models/update-luis-list-entity-model';
import { UpdateLuisPhraseModel } from '@app/models/update-luis-phrase-model';
import { UpdateLuisRegexEntityModel } from '@app/models/update-luis-regex-entity-model';
import { Observable, of, throwError, Subject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatusCode } from './http-status-code';
import { ProxyStatus } from './proxy-status';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class LuisServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  getApplications(params?: {
    [param: string]: string | string[];
  }, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<LuisApplicationModel[]>> {
    let url = `${this.baseUrl}/api/luisapplications/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      params: params,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processGetApplicationsResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processGetApplicationsResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<LuisApplicationModel[]>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<LuisApplicationModel[]>>><any>throwError(response);
      }
    }));
  }

  exportApplication(id: string, type: string, token: any): Observable<any> {
    let url = `${this.baseUrl}/api/luisapplications/${id}/export?type=${type}`;
    let subject = new Subject<any>();
    let xhr = new XMLHttpRequest();

    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          let date = new Date();
          let month = date.getMonth() + 1;
          let data_url = URL.createObjectURL(xhr.response);

          subject.next({
            url: data_url,
            fileName: `${date.getFullYear()}${month < 10 ? '0' + month : month}${date.getDate()}.${type}`
          });

          window.URL.revokeObjectURL(url);
        } else {
          subject.next(xhr.statusText);
        }
      }
    };
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.send();

    return subject.asObservable();
  }

  importApplication(id: string, file: File, headers?: {
    [name: string]: string | string[];
  }): Observable<any> {
    let url = `${this.baseUrl}/api/luisapplications/${id}/import`;
    let subject = new Subject<any>();

    var combinedHeaders = {
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    const formData = new FormData();
    formData.append('files', file, file.name);

    let options: any = {
      body: formData,
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders(combinedHeaders)
    };

    this.http.request("post", url, options).pipe(catchError((response: any) => {
      return of(response);
    })).subscribe((response: any) => {
      if (response.type == HttpEventType.UploadProgress) {
        let processed = Math.round(response.loaded / response.total * 100);
        subject.next({
          processed: processed
        });

        return;
      }

      if (response.type === HttpEventType.Response) {
        let result = response instanceof HttpResponse ? response : undefined;
        let status = result.status;
        let error = response instanceof HttpErrorResponse ? response.error : undefined;

        if (status === HttpStatusCode.Ok) {
          subject.next({
            message: result.body
          });

          return;
        }

        if (status === HttpStatusCode.badRequest) {
          let badRequest = new BadRequestModel(error);
          subject.next({
            errors: badRequest
          });

          return;
        }

        subject.next({
          error: error
        });
      }
    });

    return subject.asObservable();
  }

  protected processGetApplicationsResult(response: HttpResponseBase): Observable<ProxyStatus<LuisApplicationModel[]>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    if (status === HttpStatusCode.Ok) {
      return of(new ProxyStatus(status, result));
    }

    return of(new ProxyStatus(status, []));
  }

  getApplication(id: string, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<LuisApplicationModel>> {
    let url = `${this.baseUrl}/api/luisapplications/${id}`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("get", url, options).pipe(mergeMap((response: any) => {
      return this.processGetModelResult<LuisApplicationModel>(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processGetModelResult<LuisApplicationModel>(response);
        } catch (e) {
          return <Observable<ProxyStatus<LuisApplicationModel>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<LuisApplicationModel>>><any>throwError(response);
      }
    }));
  }

  protected processGetModelResult<T>(response: HttpResponseBase): Observable<ProxyStatus<T>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    if (status === HttpStatusCode.Ok) {
      return of(new ProxyStatus(status, result));
    }

    return of(new ProxyStatus(status, undefined));
  }

  deleteApplication(id: string, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/luisapplications/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: [id],
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("delete", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  protected processOperationResult(response: HttpResponseBase): Observable<ProxyStatus<string | BadRequestModel>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : '';
    const error = response instanceof HttpErrorResponse ? response.error : undefined;

    if (status === HttpStatusCode.Ok || status === HttpStatusCode.Created) {
      return of(new ProxyStatus(status, result));
    }

    if (status === HttpStatusCode.badRequest) {
      let badRequest = new BadRequestModel(error);
      return of(new ProxyStatus(status, badRequest));
    }

    if (error) {
      return of(new ProxyStatus(status, error));
    }

    return of(new ProxyStatus(status, ''));
  }

  addApplication(application: NewLuisApplicationModel, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/luisapplications/`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: application,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  updateApplication(id: string, application: UpdateLuisApplicationModel, headers?: {
    [name: string]: string | string[];
  }): Observable<ProxyStatus<string | BadRequestModel>> {
    let url = `${this.baseUrl}/api/luisapplications/${id}`;

    var combinedHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    let options: any = {
      body: application,
      observe: "response",
      headers: new HttpHeaders(combinedHeaders)
    };

    return this.http.request("put", url, options).pipe(mergeMap((response: any) => {
      return this.processOperationResult(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processOperationResult(response);
        } catch (e) {
          return <Observable<ProxyStatus<string>>><any>throwError(e);
        }
      } else {
        return <Observable<ProxyStatus<string>>><any>throwError(response);
      }
    }));
  }

  protected processPagedResult<T>(response: HttpResponseBase): Observable<ProxyStatus<PagedResultModel<T>>> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;

    let pagedResult = new PagedResultModel<T>();
    if (status === HttpStatusCode.Ok) {
      pagedResult.total = <number>result.total;
      pagedResult.rows = <T[]>result.rows;
      return of(new ProxyStatus(status, pagedResult));
    }

    pagedResult.total = 0;
    pagedResult.rows = [];
    return of(new ProxyStatus(status, pagedResult));
  }
}
