export class BadRequestModel {
  [key: string]: Array<string>;

  constructor(error: object) {
    for (var property in error) {
      if (error.hasOwnProperty(property)) {
        this[property] = error[property];
      }
    }
  }
}
