import { Observable, throwError, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';
import { AuthenticatedResultModel } from '@app/models/authenticated-result-model';
import { PasswordGrantModel } from '@app/models/password-grant-model';
import { UnauthorizedModel } from '@app/models/unauthorized-model';
import { BadRequestModel } from '@app/models/bad-request-model';
import { RefreshTokenModel } from '@app/models/refresh-token-model';
import { API_BASE_URL } from './service-proxy';

@Injectable()
export class TokenServiceProxy {
  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  getToken(model: PasswordGrantModel | null | undefined): Observable<AuthenticatedResultModel | UnauthorizedModel | BadRequestModel> {
    let url = this.baseUrl + "/api/auth/token";
    const content = JSON.stringify(model);

    let options: any = {
      body: content,
      observe: "response",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
      return this.processAuthenticate(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processAuthenticate(response);
        } catch (e) {
          return <Observable<AuthenticatedResultModel>><any>throwError(e);
        }
      } else {
        return <Observable<AuthenticatedResultModel>><any>throwError(response);
      }
    }));
  }

  protected processAuthenticate(response: HttpResponseBase): Observable<AuthenticatedResultModel | UnauthorizedModel | BadRequestModel> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;
    const error = response instanceof HttpErrorResponse ? response.error : undefined;

    if (status === 200) {
      let authenticated = new AuthenticatedResultModel(result);
      return of(authenticated);
    }

    if (status === 400) {
      let badRequest = new BadRequestModel(error);
      return of(badRequest);
    }

    if (status === 401) {
      let unauthorized = new UnauthorizedModel(error);
      return of(unauthorized);
    }

    return of<AuthenticatedResultModel>(<any>null);
  }

  refreshToken(model: RefreshTokenModel): Observable<AuthenticatedResultModel | BadRequestModel> {
    let url = this.baseUrl + "/api/auth/refreshtoken";

    let options: any = {
      body: model,
      observe: "response",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url, options).pipe(mergeMap((response: any) => {
      return this.processRefreshToken(response);
    })).pipe(catchError((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processRefreshToken(response);
        } catch (e) {
          return <Observable<AuthenticatedResultModel>><any>throwError(e);
        }
      } else {
        return <Observable<AuthenticatedResultModel>><any>throwError(response);
      }
    }));
  }

  protected processRefreshToken(response: HttpResponseBase): Observable<AuthenticatedResultModel | BadRequestModel> {
    const status = response.status;
    const result = response instanceof HttpResponse ? response.body : undefined;
    let error = response instanceof HttpErrorResponse ? response.error : undefined;

    if (status === 200) {
      let authenticated = new AuthenticatedResultModel(result);
      return of(authenticated);
    }

    if (status === 400) {
      let badRequest = new BadRequestModel(error);
      return of(badRequest);
    }

    return of<AuthenticatedResultModel>(<any>null);
  }
}
