import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BadRequestModel } from '../models/bad-request-model';
import { PagedResultModel } from '../models/paged-result-model';
import { UserGroupModel } from '../models/user-group-model';
import { UserGroupServiceProxy } from '../proxies/user-group-service-proxy';
import { MessageService } from './message-service';

@Injectable()
export class UserGroupService {
    private messageService: MessageService;
    private userGroupServiceProxy: UserGroupServiceProxy;

    constructor(
        messageService: MessageService,
        userGroupServiceProxy: UserGroupServiceProxy
    ) {
        this.messageService = messageService;
        this.userGroupServiceProxy = userGroupServiceProxy;
    }

    getUserGroups(page: number, size: number): Observable<PagedResultModel<UserGroupModel>> {
        return this.userGroupServiceProxy.getUserGroups({
            'pageIndex': page.toString(),
            'pageSize': size.toString()
        }).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    getUserGroup(id: string): Observable<UserGroupModel> {
        return this.userGroupServiceProxy.getUserGroup(id).pipe(mergeMap((proxy) => {
            return of(proxy.result);
        }));
    }

    addUserGroup(userGroup: UserGroupModel): void {
        this.userGroupServiceProxy.addUserGroup(userGroup).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self.messageService.sendMessage(value);
                    });
                }
                return;
            }

            this.messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    updateUserGroup(id: string, userGroup: UserGroupModel): void {
        this.userGroupServiceProxy.updateUserGroup(id, userGroup).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self.messageService.sendMessage(value);
                    });
                }
                return;
            }

            this.messageService.sendMessage({
                'message': <string>proxy.result,
                'code': proxy.statusCode
            });
        });
    }

    deleteUserGroup(id: string): void {
        this.userGroupServiceProxy.deleteUserGroup(id).subscribe((proxy) => {
            if (proxy.result instanceof BadRequestModel) {
                let self = this;
                for (let error in proxy.result) {
                    (<Array<string>>proxy.result[error]).forEach(function (value) {
                        self.messageService.sendMessage(value);
                    });
                }
                return;
            }

            this.messageService.sendMessage(<string>proxy.result);
        });
    }
}
