export class DeleteDialogDataModel<T> {
  title: string = 'Deletion Dialog';

  content: string = 'Are you sure?';

  data: T | undefined;

  yes: string = 'Yes';

  no: string = 'No';
}
